.carousel-slide {
    position: relative;
  }
  
  .carousel-slide img {
    /* width: fit-content; */
    max-width: 350px;
    object-fit: fill;
    height: 250px; /* Set a default height for all screen sizes */
  }

  .carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
  }
  
  @media screen and (max-width: 650px) {
    .carousel-slide img {
      max-height: 200px; /* Adjust the maximum height for mobile */
    }
  } 